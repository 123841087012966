export default {
  menu: {
    my_files: 'My Files',
    reader: 'Reader',
    dictionary: 'Dictionary',
    bible: 'Bible',
    songs: 'Songs',
    my_ideograms: 'My Ideograms',
    browser: 'Browser',
    settings: 'Settings',
    video: 'Video',
    about: 'About',
    logout: 'Logout',
    reload: 'Reload',
  },
  app: {
    editor: '拼字 Pinzi  - Editor',
    bible: '拼字 Pinzi  - Bible',
    songs: '拼字 Pinzi  - Songs',
    dictionary: '拼字 Pinzi  - Dictionary',
    videos: '拼字 Pinzi  - Videos',
  },
  visualization_mode: 'Visualization mode',
  edition_mode: 'Edition mode',
  delete: 'Delete',
  filename: 'Filename',
  cancel: 'Cancel',
  ok: 'Ok',
  new_file: 'New File',
  delete_file: 'Delete File',
  sure_delete_file: 'Are you sure to delete this file?',
  options: 'Options',
  line: 'Line',
  add_empty_block: 'Add empty block',
  convert_to_pinyin: 'Convert to pinyin',
  paste: 'Paste',
  remove_line: 'Remove line',
  sign_in_google: 'Sign in with Google',
  sign_in_baidu: 'Sign in with Baidu',
  action: 'Action',
  paste_action: {
    ideograms: 'Ideograms only',
    ideograms_spaced: 'Ideograms spaced',
  },
  add_ideogram: 'Add Ideogram',
  remove_ideogram: 'Remove Ideogram',
  confirmation_add_ideogram:
    'Would you like to add "{ideogram}" to your known list?',
  confirmation_remove_ideogram:
    'Would you like to remove "{ideogram}" to your known list?',
  my_total: 'My Total',
  my_ideograms: 'My ideograms',
  summary: 'Summary',
  ideogram: 'Ideogram',
  frequency: 'Frequency',
  total: 'Total',
  pronunciation: 'Pronunciation',
  percent: 'Percent',
  settings: 'Settings',
  size: 'Size',
  normal: 'Normal',
  larger: 'Larger',
  type: 'Type',
  without_knew: 'Without knew',
  with_unknew: 'Just unknew',
  all: 'All',
  without_any: 'Without any',
  pinyin_only: 'Pinyin only',
  save: 'Save',
  ideograms_colored: 'Colored ideograms',
  yes: 'Yes',
  no: 'No',
  ideograms_spaced: 'Spaced ideograms',
  search: 'Search',
  tone_1_color: 'Tone Color 1',
  tone_2_color: 'Tone Color 2',
  tone_3_color: 'Tone Color 3',
  tone_4_color: 'Tone Color 4',
  tone_0_color: 'Neutral Tone Color',
  restore_default: 'Restore Default',
  definition: 'Definition',
  stroke: 'Stroke',
  unknown: 'Unknown',
  known: 'Known',
  url: 'URL',
  message_no_track: 'No subtitles to this video',
  download_track: 'Download Subtitle',
  hide_pinyin_by: 'Hide Pinyin By',
  word: 'Word',
  'freq.': 'Freq.',
  ideograms: 'Ideograms',
  words: 'Words',
  edit: 'Edit',
  translation: 'Translation',
  translation_language: 'Translation Language',
  language: 'Language',
  no_translation: 'No Translation',
  portuguese: 'Portuguese',
  english: 'English',
  spanish: 'Spanish',
  korean: 'Korean',
  japanese: 'Japanese',
  italian: 'Italian',
  french: 'French',
  german: 'German',
  'chinese.simplified': 'Chinese Mandarin (Simplified)',
  'chinese.traditional': 'Chinese Mandarin (Traditional)',
  close: 'Close',
  import_site: 'Import Site',
  import: 'Import',
  no_results: 'No Results',
  show: 'Show',
  join_left: 'Join left',
  download_pleco_dictionary: 'Download to Pleco',
  split: 'Split',
  show_track: 'Show Track',
  hide_track: 'Hide Track',
  copied_to_clipboard: 'Copied to clipboard',
  ideogram_type: 'Ideogram Type',
  simplified: 'Simplified',
  traditional: 'Traditional',
  no_internet: 'No Internet conection',
  confirmation: 'Confirmation',
  confirmation_download_bible: 'Would you like to download the Bible?',
  confirmation_delete_bible: 'Would you like to delete the Bible?',
  about_text:
    'For questions or suggestions, send an email to pinzi.org@gmail.com',
  other_apps: 'Other Apps',
  share: 'Share',
  file: 'File',
  folder: 'Folder',
  new: 'New',
  name: 'Name',
  open_verses_as_popup: 'Open verses as popup',
  open_chapter_on_load: 'Open chapter on load',
  repeat_phrase: 'Repeat phrase',
  start_time: 'Start time',
  end_time: 'End time',
  start: 'Start',
  end: 'End',
  history: 'History',
  tutorials: 'Tutorials',
  margin_bottom: 'Margin Bottom',
  show_pinyin: 'Show Pinyin',
  hide_pinyin_from: 'Hide Pinyin From',
  warning: 'Warning',
  '2pinyin_add_remove_warning':
    'You can only add / remove ideograms at 2pinyin.net',
  view: 'View',
  saved_successfully: 'Saved successfully',
  pinyin_ideograms: 'Pinyin + Ideograms',
  ideograms_only: 'Ideograms only',
  measure_words: 'Measure Words',
  separable_verb: 'Separable verb',
  my_total_ideograms: 'Ideograms Total',
  Pinyin: 'Pinyin',
  translate: 'translate',
  antonyms: 'Antonyms',
  synonyms: 'Synonyms',
  text: 'Text',
  open_pleco: 'Open Pleco',
};
