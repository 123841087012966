<template>
  <div>
    <span @click="$router.push('/bible')" class="md-title">
      <md-icon>arrow_back</md-icon>
    </span>
  </div>
</template>
<script>

export default {
  name: 'bible-save-top-bar',
};
</script>
