export default {
  menu: {
    my_files: 'Meus Arquivos',
    reader: 'Leitor',
    dictionary: 'Dicionário',
    bible: 'Bíblia',
    songs: 'Cânticos',
    my_ideograms: 'Meus Ideogramas',
    browser: 'Navegador',
    settings: 'Configurações',
    video: 'Vídeo',
    about: 'Sobre',
    logout: 'Sair',
    reload: 'Recarregar',
  },
  app: {
    editor: '拼字 Pinzi - Editor',
    bible: '拼字 Pinzi - Bíblia',
    songs: '拼字 Pinzi  - Cânticos',
    dictionary: '拼字 Pinzi - Dicionário',
    videos: '拼字 Pinzi - Vídeos',
  },
  visualization_mode: 'Modo visualização',
  edition_mode: 'Modo edição',
  delete: 'Apagar',
  filename: 'Arquivo',
  cancel: 'Cancelar',
  ok: 'Ok',
  new_file: 'Novo Arquivo',
  delete_file: 'Apagar Arquivo',
  sure_delete_file: 'Tem certeza que deseja apagar esse arquivo?',
  options: 'Opções',
  line: 'Linha',
  add_empty_block: 'Adicionar bloco vazio',
  convert_to_pinyin: 'Converter para pinyin',
  paste: 'Colar',
  remove_line: 'Apagar linha',
  sign_in_google: 'Entrar com Google',
  sign_in_baidu: 'Entrar com Baidu',
  action: 'Ação',
  paste_action: {
    ideograms: 'Somente ideogramas',
    ideograms_spaced: 'Ideogramas espaçados',
  },
  add_ideogram: 'Adicionar Ideograma',
  remove_ideogram: 'Remover Ideograma',
  confirmation_add_ideogram: 'Gostaria de adicionar "{ideogram}" na sua lista?',
  confirmation_remove_ideogram: 'Gostaria de remover "{ideogram}" da sua lista',
  my_total: 'Meu Total',
  my_ideograms: 'Meus ideogramas',
  summary: 'Resumo',
  ideogram: 'Ideograma',
  frequency: 'Frequência',
  total: 'Total',
  pronunciation: 'Pronúncia',
  percent: 'Percentual',
  settings: 'Configurações',
  size: 'Tamanho',
  normal: 'Normal',
  larger: 'Grande',
  type: 'Tipo',
  without_knew: 'Sem conhecidos',
  with_unknew: 'Só os desconhecidos',
  all: 'Todos',
  without_any: 'Sem nenhum',
  pinyin_only: 'Somente Pinyin',
  save: 'Salvar',
  ideograms_colored: 'Ideogramas coloridos',
  yes: 'Sim',
  no: 'Não',
  ideograms_spaced: 'Ideogramas espaçados',
  search: 'Buscar',
  tone_1_color: 'Cor Tom 1',
  tone_2_color: 'Cor Tom 2',
  tone_3_color: 'Cor Tom 3',
  tone_4_color: 'Cor Tom 4',
  tone_0_color: 'Cor Tom Neutro',
  restore_default: 'Restaurar Padrão',
  definition: 'Definição',
  stroke: 'Traços',
  unknown: 'Desconhecidos',
  known: 'Conhecidos',
  url: 'URL',
  message_no_track: 'Não há legendas separadas pra esse vídeo',
  download_track: 'Baixar Legenda',
  hide_pinyin_by: 'Esconder Pinyin Por',
  word: 'Palavra',
  'freq.': 'Freq.',
  ideograms: 'Ideogramas',
  words: 'Palavras',
  edit: 'Editar',
  translation: 'Tradução',
  translation_language: 'Idioma de Tradução',
  language: 'Idioma',
  no_translation: 'Não Traduzir',
  portuguese: 'Português',
  english: 'Inglês',
  spanish: 'Espanhol',
  korean: 'Coreano',
  japanese: 'Japonês',
  italian: 'Italiano',
  french: 'Francês',
  german: 'Alemão',
  'chinese.simplified': 'Chinês Mandarim (Simplificado)',
  'chinese.traditional': 'Chinês Mandarim (Tradicional)',
  close: 'Fechar',
  import_site: 'Importar Site',
  import: 'Importar',
  no_results: 'Não foram encontrados resultados',
  show: 'Mostrar',
  join_left: 'Juntar à esquerda',
  download_pleco_dictionary: 'Baixar para Pleco',
  split: 'Separar',
  show_track: 'Mostrar Legenda',
  hide_track: 'Esconder Legenda',
  copied_to_clipboard: 'Copiado para a área de transferência',
  ideogram_type: 'Tipo de Ideograma',
  simplified: 'Simplificado',
  traditional: 'Tradicional',
  no_internet: 'Sem conexão com a internet',
  confirmation: 'Confirmação',
  confirmation_download_bible: 'Tem certeza que deseja baixar essa Bíblia?',
  confirmation_delete_bible: 'Tem certeza que deseja apagar essa Bíblia?',
  about_text:
    'Para dúvidas ou sugestões, mande um e-mail para pinzi.org@gmail.com',
  other_apps: 'Outros Aplicativos',
  share: 'Compartilhar',
  file: 'Arquivo',
  folder: 'Pasta',
  new: 'Novo',
  name: 'Nome',
  open_verses_as_popup: 'Abrir versículos como popup',
  open_chapter_on_load: 'Abrir capítulo ao carregar',
  repeat_phrase: 'Repetir frase',
  start_time: 'Tempo inicial',
  end_time: 'Tempo final',
  start: 'Iniciar',
  end: 'Terminar',
  history: 'Histórico',
  tutorials: 'Tutoriais',
  margin_bottom: 'Margem de Baixo',
  show_pinyin: 'Mostrar Pinyin',
  hide_pinyin_from: 'Esconder Pinyin De',
  warning: 'Aviso',
  '2pinyin_add_remove_warning':
    'Só é possível adicionar / remover ideogramas no 2pinyin.net',
  view: 'Ver',
  saved_successfully: 'Salvo com sucesso',
  pinyin_ideograms: 'Pinyin + Ideogramas',
  ideograms_only: 'Somente ideograms',
  measure_words: 'Classificadores',
  separable_verb: 'Verbo separável',
  my_total_ideograms: 'Total de Ideogramas',
  Pinyin: 'Pinyin',
  translate: 'traduzir',
  antonyms: 'Antônimos',
  synonyms: 'Sinônimos',
  text: 'Texto',
  open_pleco: 'Abrir Pleco',
};
