module.exports = [
  '，',
  '。',
  '：',
  '；',
  '、',
  '？',
  '（',
  '）',
  '！',
  '“',
  '”',
  '《',
  '》',
  '…',
  '—',
  '［',
  '］',
  '·',
  '’',
  '‘',
  '─',
  '.',
  ':',
  '．',
  '-',
  '"',
  '*',
  '「',
  '」',
  '[',
  ']',
  '#',
  '<',
  '>',
  '〈',
  '〉',
  '●',
  '○',
  '『',
  '』',
];
