/* eslint-disable */
module.exports = {
  'genesis': [
    { c: 1, t: 31 },
    { c: 2, t: 25 },
    { c: 3, t: 24 },
    { c: 4, t: 26 },
    { c: 5, t: 32 },
    { c: 6, t: 22 },
    { c: 7, t: 24 },
    { c: 8, t: 22 },
    { c: 9, t: 29 },
    { c: 10, t: 32 },
    { c: 11, t: 32 },
    { c: 12, t: 20 },
    { c: 13, t: 18 },
    { c: 14, t: 24 },
    { c: 15, t: 21 },
    { c: 16, t: 16 },
    { c: 17, t: 27 },
    { c: 18, t: 33 },
    { c: 19, t: 38 },
    { c: 20, t: 18 },
    { c: 21, t: 34 },
    { c: 22, t: 24 },
    { c: 23, t: 20 },
    { c: 24, t: 67 },
    { c: 25, t: 34 },
    { c: 26, t: 35 },
    { c: 27, t: 46 },
    { c: 28, t: 22 },
    { c: 29, t: 35 },
    { c: 30, t: 43 },
    { c: 31, t: 55 },
    { c: 32, t: 32 },
    { c: 33, t: 20 },
    { c: 34, t: 31 },
    { c: 35, t: 29 },
    { c: 36, t: 43 },
    { c: 37, t: 36 },
    { c: 38, t: 30 },
    { c: 39, t: 23 },
    { c: 40, t: 23 },
    { c: 41, t: 57 },
    { c: 42, t: 38 },
    { c: 43, t: 34 },
    { c: 44, t: 34 },
    { c: 45, t: 28 },
    { c: 46, t: 34 },
    { c: 47, t: 31 },
    { c: 48, t: 22 },
    { c: 49, t: 33 },
    { c: 50, t: 26 },
  ],
  'exodus': [
    { c: 1, t: 22 },
    { c: 2, t: 25 },
    { c: 3, t: 22 },
    { c: 4, t: 31 },
    { c: 5, t: 23 },
    { c: 6, t: 30 },
    { c: 7, t: 25 },
    { c: 8, t: 32 },
    { c: 9, t: 35 },
    { c: 10, t: 29 },
    { c: 11, t: 10 },
    { c: 12, t: 51 },
    { c: 13, t: 22 },
    { c: 14, t: 31 },
    { c: 15, t: 27 },
    { c: 16, t: 36 },
    { c: 17, t: 16 },
    { c: 18, t: 27 },
    { c: 19, t: 25 },
    { c: 20, t: 26 },
    { c: 21, t: 36 },
    { c: 22, t: 31 },
    { c: 23, t: 33 },
    { c: 24, t: 18 },
    { c: 25, t: 40 },
    { c: 26, t: 37 },
    { c: 27, t: 21 },
    { c: 28, t: 43 },
    { c: 29, t: 46 },
    { c: 30, t: 38 },
    { c: 31, t: 18 },
    { c: 32, t: 35 },
    { c: 33, t: 23 },
    { c: 34, t: 35 },
    { c: 35, t: 35 },
    { c: 36, t: 38 },
    { c: 37, t: 29 },
    { c: 38, t: 31 },
    { c: 39, t: 43 },
    { c: 40, t: 38 },
  ],
  'leviticus': [
    { c: 1, t: 17 },
    { c: 2, t: 16 },
    { c: 3, t: 17 },
    { c: 4, t: 35 },
    { c: 5, t: 19 },
    { c: 6, t: 30 },
    { c: 7, t: 38 },
    { c: 8, t: 36 },
    { c: 9, t: 24 },
    { c: 10, t: 20 },
    { c: 11, t: 47 },
    { c: 12, t: 8 },
    { c: 13, t: 59 },
    { c: 14, t: 57 },
    { c: 15, t: 33 },
    { c: 16, t: 34 },
    { c: 17, t: 16 },
    { c: 18, t: 30 },
    { c: 19, t: 37 },
    { c: 20, t: 27 },
    { c: 21, t: 24 },
    { c: 22, t: 33 },
    { c: 23, t: 44 },
    { c: 24, t: 23 },
    { c: 25, t: 55 },
    { c: 26, t: 46 },
    { c: 27, t: 34 },
  ],
  'numbers': [
    { c: 1, t: 54 },
    { c: 2, t: 34 },
    { c: 3, t: 51 },
    { c: 4, t: 49 },
    { c: 5, t: 31 },
    { c: 6, t: 27 },
    { c: 7, t: 89 },
    { c: 8, t: 26 },
    { c: 9, t: 23 },
    { c: 10, t: 36 },
    { c: 11, t: 35 },
    { c: 12, t: 16 },
    { c: 13, t: 33 },
    { c: 14, t: 45 },
    { c: 15, t: 41 },
    { c: 16, t: 50 },
    { c: 17, t: 13 },
    { c: 18, t: 32 },
    { c: 19, t: 22 },
    { c: 20, t: 29 },
    { c: 21, t: 35 },
    { c: 22, t: 41 },
    { c: 23, t: 30 },
    { c: 24, t: 25 },
    { c: 25, t: 18 },
    { c: 26, t: 65 },
    { c: 27, t: 23 },
    { c: 28, t: 31 },
    { c: 29, t: 40 },
    { c: 30, t: 16 },
    { c: 31, t: 54 },
    { c: 32, t: 42 },
    { c: 33, t: 56 },
    { c: 34, t: 29 },
    { c: 35, t: 34 },
    { c: 36, t: 13 },
  ],
  'deuteronomy': [
    { c: 1, t: 46 },
    { c: 2, t: 37 },
    { c: 3, t: 29 },
    { c: 4, t: 49 },
    { c: 5, t: 33 },
    { c: 6, t: 25 },
    { c: 7, t: 26 },
    { c: 8, t: 20 },
    { c: 9, t: 29 },
    { c: 10, t: 22 },
    { c: 11, t: 32 },
    { c: 12, t: 32 },
    { c: 13, t: 18 },
    { c: 14, t: 29 },
    { c: 15, t: 23 },
    { c: 16, t: 22 },
    { c: 17, t: 20 },
    { c: 18, t: 22 },
    { c: 19, t: 21 },
    { c: 20, t: 20 },
    { c: 21, t: 23 },
    { c: 22, t: 30 },
    { c: 23, t: 25 },
    { c: 24, t: 22 },
    { c: 25, t: 19 },
    { c: 26, t: 19 },
    { c: 27, t: 26 },
    { c: 28, t: 68 },
    { c: 29, t: 29 },
    { c: 30, t: 20 },
    { c: 31, t: 30 },
    { c: 32, t: 52 },
    { c: 33, t: 29 },
    { c: 34, t: 12 },
  ],
  'joshua': [
    { c: 1, t: 18 },
    { c: 2, t: 24 },
    { c: 3, t: 17 },
    { c: 4, t: 24 },
    { c: 5, t: 15 },
    { c: 6, t: 27 },
    { c: 7, t: 26 },
    { c: 8, t: 35 },
    { c: 9, t: 27 },
    { c: 10, t: 43 },
    { c: 11, t: 23 },
    { c: 12, t: 24 },
    { c: 13, t: 33 },
    { c: 14, t: 15 },
    { c: 15, t: 63 },
    { c: 16, t: 10 },
    { c: 17, t: 18 },
    { c: 18, t: 28 },
    { c: 19, t: 51 },
    { c: 20, t: 9 },
    { c: 21, t: 45 },
    { c: 22, t: 34 },
    { c: 23, t: 16 },
    { c: 24, t: 33 },
  ],
  'judges': [
    { c: 1, t: 36 },
    { c: 2, t: 23 },
    { c: 3, t: 31 },
    { c: 4, t: 24 },
    { c: 5, t: 31 },
    { c: 6, t: 40 },
    { c: 7, t: 25 },
    { c: 8, t: 35 },
    { c: 9, t: 57 },
    { c: 10, t: 18 },
    { c: 11, t: 40 },
    { c: 12, t: 15 },
    { c: 13, t: 25 },
    { c: 14, t: 20 },
    { c: 15, t: 20 },
    { c: 16, t: 31 },
    { c: 17, t: 13 },
    { c: 18, t: 31 },
    { c: 19, t: 30 },
    { c: 20, t: 48 },
    { c: 21, t: 25 },
  ],
  'ruth': [
    { c: 1, t: 22 },
    { c: 2, t: 23 },
    { c: 3, t: 18 },
    { c: 4, t: 22 },
  ],
  '1samuel': [
    { c: 1, t: 28 },
    { c: 2, t: 36 },
    { c: 3, t: 21 },
    { c: 4, t: 22 },
    { c: 5, t: 12 },
    { c: 6, t: 21 },
    { c: 7, t: 17 },
    { c: 8, t: 22 },
    { c: 9, t: 27 },
    { c: 10, t: 27 },
    { c: 11, t: 15 },
    { c: 12, t: 25 },
    { c: 13, t: 23 },
    { c: 14, t: 52 },
    { c: 15, t: 35 },
    { c: 16, t: 23 },
    { c: 17, t: 58 },
    { c: 18, t: 30 },
    { c: 19, t: 24 },
    { c: 20, t: 42 },
    { c: 21, t: 15 },
    { c: 22, t: 23 },
    { c: 23, t: 29 },
    { c: 24, t: 22 },
    { c: 25, t: 44 },
    { c: 26, t: 25 },
    { c: 27, t: 12 },
    { c: 28, t: 25 },
    { c: 29, t: 11 },
    { c: 30, t: 31 },
    { c: 31, t: 13 },
  ],
  '2samuel': [
    { c: 1, t: 27 },
    { c: 2, t: 32 },
    { c: 3, t: 39 },
    { c: 4, t: 12 },
    { c: 5, t: 25 },
    { c: 6, t: 23 },
    { c: 7, t: 29 },
    { c: 8, t: 18 },
    { c: 9, t: 13 },
    { c: 10, t: 19 },
    { c: 11, t: 27 },
    { c: 12, t: 31 },
    { c: 13, t: 39 },
    { c: 14, t: 33 },
    { c: 15, t: 37 },
    { c: 16, t: 23 },
    { c: 17, t: 29 },
    { c: 18, t: 33 },
    { c: 19, t: 43 },
    { c: 20, t: 26 },
    { c: 21, t: 22 },
    { c: 22, t: 51 },
    { c: 23, t: 39 },
    { c: 24, t: 25 },
  ],
  '1kings': [
    { c: 1, t: 53 },
    { c: 2, t: 46 },
    { c: 3, t: 28 },
    { c: 4, t: 34 },
    { c: 5, t: 18 },
    { c: 6, t: 38 },
    { c: 7, t: 51 },
    { c: 8, t: 66 },
    { c: 9, t: 28 },
    { c: 10, t: 29 },
    { c: 11, t: 43 },
    { c: 12, t: 33 },
    { c: 13, t: 34 },
    { c: 14, t: 31 },
    { c: 15, t: 34 },
    { c: 16, t: 34 },
    { c: 17, t: 24 },
    { c: 18, t: 46 },
    { c: 19, t: 21 },
    { c: 20, t: 43 },
    { c: 21, t: 29 },
    { c: 22, t: 53 },
  ],
  '2kings': [
    { c: 1, t: 18 },
    { c: 2, t: 25 },
    { c: 3, t: 27 },
    { c: 4, t: 44 },
    { c: 5, t: 27 },
    { c: 6, t: 33 },
    { c: 7, t: 20 },
    { c: 8, t: 29 },
    { c: 9, t: 37 },
    { c: 10, t: 36 },
    { c: 11, t: 21 },
    { c: 12, t: 21 },
    { c: 13, t: 25 },
    { c: 14, t: 29 },
    { c: 15, t: 38 },
    { c: 16, t: 20 },
    { c: 17, t: 41 },
    { c: 18, t: 37 },
    { c: 19, t: 37 },
    { c: 20, t: 21 },
    { c: 21, t: 26 },
    { c: 22, t: 20 },
    { c: 23, t: 37 },
    { c: 24, t: 20 },
    { c: 25, t: 30 },
  ],
  '1chronicles': [
    { c: 1, t: 54 },
    { c: 2, t: 55 },
    { c: 3, t: 24 },
    { c: 4, t: 43 },
    { c: 5, t: 26 },
    { c: 6, t: 81 },
    { c: 7, t: 40 },
    { c: 8, t: 40 },
    { c: 9, t: 44 },
    { c: 10, t: 14 },
    { c: 11, t: 47 },
    { c: 12, t: 40 },
    { c: 13, t: 14 },
    { c: 14, t: 17 },
    { c: 15, t: 29 },
    { c: 16, t: 43 },
    { c: 17, t: 27 },
    { c: 18, t: 17 },
    { c: 19, t: 19 },
    { c: 20, t: 8 },
    { c: 21, t: 30 },
    { c: 22, t: 19 },
    { c: 23, t: 32 },
    { c: 24, t: 31 },
    { c: 25, t: 31 },
    { c: 26, t: 32 },
    { c: 27, t: 34 },
    { c: 28, t: 21 },
    { c: 29, t: 30 },
  ],
  '2chronicles': [
    { c: 1, t: 17 },
    { c: 2, t: 18 },
    { c: 3, t: 17 },
    { c: 4, t: 22 },
    { c: 5, t: 14 },
    { c: 6, t: 42 },
    { c: 7, t: 22 },
    { c: 8, t: 18 },
    { c: 9, t: 31 },
    { c: 10, t: 19 },
    { c: 11, t: 23 },
    { c: 12, t: 16 },
    { c: 13, t: 22 },
    { c: 14, t: 15 },
    { c: 15, t: 19 },
    { c: 16, t: 14 },
    { c: 17, t: 19 },
    { c: 18, t: 34 },
    { c: 19, t: 11 },
    { c: 20, t: 37 },
    { c: 21, t: 20 },
    { c: 22, t: 12 },
    { c: 23, t: 21 },
    { c: 24, t: 27 },
    { c: 25, t: 28 },
    { c: 26, t: 23 },
    { c: 27, t: 9 },
    { c: 28, t: 27 },
    { c: 29, t: 36 },
    { c: 30, t: 27 },
    { c: 31, t: 21 },
    { c: 32, t: 33 },
    { c: 33, t: 25 },
    { c: 34, t: 33 },
    { c: 35, t: 27 },
    { c: 36, t: 23 },
  ],
  'ezra': [
    { c: 1, t: 11 },
    { c: 2, t: 70 },
    { c: 3, t: 13 },
    { c: 4, t: 24 },
    { c: 5, t: 17 },
    { c: 6, t: 22 },
    { c: 7, t: 28 },
    { c: 8, t: 36 },
    { c: 9, t: 15 },
    { c: 10, t: 44 },
  ],
  'nehemiah': [
    { c: 1, t: 11 },
    { c: 2, t: 20 },
    { c: 3, t: 32 },
    { c: 4, t: 23 },
    { c: 5, t: 19 },
    { c: 6, t: 19 },
    { c: 7, t: 73 },
    { c: 8, t: 18 },
    { c: 9, t: 38 },
    { c: 10, t: 39 },
    { c: 11, t: 36 },
    { c: 12, t: 47 },
    { c: 13, t: 31 },
  ],
  'esther': [
    { c: 1, t: 22 },
    { c: 2, t: 23 },
    { c: 3, t: 15 },
    { c: 4, t: 17 },
    { c: 5, t: 14 },
    { c: 6, t: 14 },
    { c: 7, t: 10 },
    { c: 8, t: 17 },
    { c: 9, t: 32 },
    { c: 10, t: 3 },
  ],
  'job': [
    { c: 1, t: 22 },
    { c: 2, t: 13 },
    { c: 3, t: 26 },
    { c: 4, t: 21 },
    { c: 5, t: 27 },
    { c: 6, t: 30 },
    { c: 7, t: 21 },
    { c: 8, t: 22 },
    { c: 9, t: 35 },
    { c: 10, t: 22 },
    { c: 11, t: 20 },
    { c: 12, t: 25 },
    { c: 13, t: 28 },
    { c: 14, t: 22 },
    { c: 15, t: 35 },
    { c: 16, t: 22 },
    { c: 17, t: 16 },
    { c: 18, t: 21 },
    { c: 19, t: 29 },
    { c: 20, t: 29 },
    { c: 21, t: 34 },
    { c: 22, t: 30 },
    { c: 23, t: 17 },
    { c: 24, t: 25 },
    { c: 25, t: 6 },
    { c: 26, t: 14 },
    { c: 27, t: 23 },
    { c: 28, t: 28 },
    { c: 29, t: 25 },
    { c: 30, t: 31 },
    { c: 31, t: 40 },
    { c: 32, t: 22 },
    { c: 33, t: 33 },
    { c: 34, t: 37 },
    { c: 35, t: 16 },
    { c: 36, t: 33 },
    { c: 37, t: 24 },
    { c: 38, t: 41 },
    { c: 39, t: 30 },
    { c: 40, t: 24 },
    { c: 41, t: 34 },
    { c: 42, t: 17 },
  ],
  'psalms': [
    { c: 1, t: 6 },
    { c: 2, t: 12 },
    { c: 3, t: 8 },
    { c: 4, t: 8 },
    { c: 5, t: 12 },
    { c: 6, t: 10 },
    { c: 7, t: 17 },
    { c: 8, t: 9 },
    { c: 9, t: 20 },
    { c: 10, t: 18 },
    { c: 11, t: 7 },
    { c: 12, t: 8 },
    { c: 13, t: 6 },
    { c: 14, t: 7 },
    { c: 15, t: 5 },
    { c: 16, t: 11 },
    { c: 17, t: 15 },
    { c: 18, t: 50 },
    { c: 19, t: 14 },
    { c: 20, t: 9 },
    { c: 21, t: 13 },
    { c: 22, t: 31 },
    { c: 23, t: 6 },
    { c: 24, t: 10 },
    { c: 25, t: 22 },
    { c: 26, t: 12 },
    { c: 27, t: 14 },
    { c: 28, t: 9 },
    { c: 29, t: 11 },
    { c: 30, t: 12 },
    { c: 31, t: 24 },
    { c: 32, t: 11 },
    { c: 33, t: 22 },
    { c: 34, t: 22 },
    { c: 35, t: 28 },
    { c: 36, t: 12 },
    { c: 37, t: 40 },
    { c: 38, t: 22 },
    { c: 39, t: 13 },
    { c: 40, t: 17 },
    { c: 41, t: 13 },
    { c: 42, t: 11 },
    { c: 43, t: 5 },
    { c: 44, t: 26 },
    { c: 45, t: 17 },
    { c: 46, t: 11 },
    { c: 47, t: 9 },
    { c: 48, t: 14 },
    { c: 49, t: 20 },
    { c: 50, t: 23 },
    { c: 51, t: 19 },
    { c: 52, t: 9 },
    { c: 53, t: 6 },
    { c: 54, t: 7 },
    { c: 55, t: 23 },
    { c: 56, t: 13 },
    { c: 57, t: 11 },
    { c: 58, t: 11 },
    { c: 59, t: 17 },
    { c: 60, t: 12 },
    { c: 61, t: 8 },
    { c: 62, t: 12 },
    { c: 63, t: 11 },
    { c: 64, t: 10 },
    { c: 65, t: 13 },
    { c: 66, t: 20 },
    { c: 67, t: 7 },
    { c: 68, t: 35 },
    { c: 69, t: 36 },
    { c: 70, t: 5 },
    { c: 71, t: 24 },
    { c: 72, t: 20 },
    { c: 73, t: 28 },
    { c: 74, t: 23 },
    { c: 75, t: 10 },
    { c: 76, t: 12 },
    { c: 77, t: 20 },
    { c: 78, t: 72 },
    { c: 79, t: 13 },
    { c: 80, t: 19 },
    { c: 81, t: 16 },
    { c: 82, t: 8 },
    { c: 83, t: 18 },
    { c: 84, t: 12 },
    { c: 85, t: 13 },
    { c: 86, t: 17 },
    { c: 87, t: 7 },
    { c: 88, t: 18 },
    { c: 89, t: 52 },
    { c: 90, t: 17 },
    { c: 91, t: 16 },
    { c: 92, t: 15 },
    { c: 93, t: 5 },
    { c: 94, t: 23 },
    { c: 95, t: 11 },
    { c: 96, t: 13 },
    { c: 97, t: 12 },
    { c: 98, t: 9 },
    { c: 99, t: 9 },
    { c: 100, t: 5 },
    { c: 101, t: 8 },
    { c: 102, t: 28 },
    { c: 103, t: 22 },
    { c: 104, t: 35 },
    { c: 105, t: 45 },
    { c: 106, t: 48 },
    { c: 107, t: 43 },
    { c: 108, t: 13 },
    { c: 109, t: 31 },
    { c: 110, t: 7 },
    { c: 111, t: 10 },
    { c: 112, t: 10 },
    { c: 113, t: 9 },
    { c: 114, t: 8 },
    { c: 115, t: 18 },
    { c: 116, t: 19 },
    { c: 117, t: 2 },
    { c: 118, t: 29 },
    { c: 119, t: 176 },
    { c: 120, t: 7 },
    { c: 121, t: 8 },
    { c: 122, t: 9 },
    { c: 123, t: 4 },
    { c: 124, t: 8 },
    { c: 125, t: 5 },
    { c: 126, t: 6 },
    { c: 127, t: 5 },
    { c: 128, t: 6 },
    { c: 129, t: 8 },
    { c: 130, t: 8 },
    { c: 131, t: 3 },
    { c: 132, t: 18 },
    { c: 133, t: 3 },
    { c: 134, t: 3 },
    { c: 135, t: 21 },
    { c: 136, t: 26 },
    { c: 137, t: 9 },
    { c: 138, t: 8 },
    { c: 139, t: 24 },
    { c: 140, t: 13 },
    { c: 141, t: 10 },
    { c: 142, t: 7 },
    { c: 143, t: 12 },
    { c: 144, t: 25 },
    { c: 145, t: 21 },
    { c: 146, t: 10 },
    { c: 147, t: 20 },
    { c: 148, t: 14 },
    { c: 149, t: 9 },
    { c: 150, t: 6 },
  ],
  'proverbs': [
    { c: 1, t: 33 },
    { c: 2, t: 22 },
    { c: 3, t: 35 },
    { c: 4, t: 27 },
    { c: 5, t: 23 },
    { c: 6, t: 35 },
    { c: 7, t: 27 },
    { c: 8, t: 36 },
    { c: 9, t: 18 },
    { c: 10, t: 32 },
    { c: 11, t: 31 },
    { c: 12, t: 28 },
    { c: 13, t: 25 },
    { c: 14, t: 35 },
    { c: 15, t: 33 },
    { c: 16, t: 33 },
    { c: 17, t: 28 },
    { c: 18, t: 24 },
    { c: 19, t: 29 },
    { c: 20, t: 30 },
    { c: 21, t: 31 },
    { c: 22, t: 29 },
    { c: 23, t: 35 },
    { c: 24, t: 34 },
    { c: 25, t: 28 },
    { c: 26, t: 28 },
    { c: 27, t: 27 },
    { c: 28, t: 28 },
    { c: 29, t: 27 },
    { c: 30, t: 33 },
    { c: 31, t: 31 },
  ],
  'ecclesiastes': [
    { c: 1, t: 18 },
    { c: 2, t: 26 },
    { c: 3, t: 22 },
    { c: 4, t: 16 },
    { c: 5, t: 20 },
    { c: 6, t: 12 },
    { c: 7, t: 29 },
    { c: 8, t: 17 },
    { c: 9, t: 18 },
    { c: 10, t: 20 },
    { c: 11, t: 10 },
    { c: 12, t: 14 },
  ],
  'songofsolomon': [
    { c: 1, t: 17 },
    { c: 2, t: 17 },
    { c: 3, t: 11 },
    { c: 4, t: 16 },
    { c: 5, t: 16 },
    { c: 6, t: 13 },
    { c: 7, t: 13 },
    { c: 8, t: 14 },
  ],
  'isaiah': [
    { c: 1, t: 31 },
    { c: 2, t: 22 },
    { c: 3, t: 26 },
    { c: 4, t: 6 },
    { c: 5, t: 30 },
    { c: 6, t: 13 },
    { c: 7, t: 25 },
    { c: 8, t: 22 },
    { c: 9, t: 21 },
    { c: 10, t: 34 },
    { c: 11, t: 16 },
    { c: 12, t: 6 },
    { c: 13, t: 22 },
    { c: 14, t: 32 },
    { c: 15, t: 9 },
    { c: 16, t: 14 },
    { c: 17, t: 14 },
    { c: 18, t: 7 },
    { c: 19, t: 25 },
    { c: 20, t: 6 },
    { c: 21, t: 17 },
    { c: 22, t: 25 },
    { c: 23, t: 18 },
    { c: 24, t: 23 },
    { c: 25, t: 13 },
    { c: 26, t: 21 },
    { c: 27, t: 13 },
    { c: 28, t: 29 },
    { c: 29, t: 24 },
    { c: 30, t: 33 },
    { c: 31, t: 9 },
    { c: 32, t: 20 },
    { c: 33, t: 24 },
    { c: 34, t: 17 },
    { c: 35, t: 10 },
    { c: 36, t: 22 },
    { c: 37, t: 38 },
    { c: 38, t: 22 },
    { c: 39, t: 8 },
    { c: 40, t: 31 },
    { c: 41, t: 29 },
    { c: 42, t: 25 },
    { c: 43, t: 28 },
    { c: 44, t: 28 },
    { c: 45, t: 25 },
    { c: 46, t: 13 },
    { c: 47, t: 15 },
    { c: 48, t: 22 },
    { c: 49, t: 26 },
    { c: 50, t: 11 },
    { c: 51, t: 23 },
    { c: 52, t: 15 },
    { c: 53, t: 12 },
    { c: 54, t: 17 },
    { c: 55, t: 13 },
    { c: 56, t: 12 },
    { c: 57, t: 21 },
    { c: 58, t: 14 },
    { c: 59, t: 21 },
    { c: 60, t: 22 },
    { c: 61, t: 11 },
    { c: 62, t: 12 },
    { c: 63, t: 19 },
    { c: 64, t: 12 },
    { c: 65, t: 25 },
    { c: 66, t: 24 },
  ],
  'jeremiah': [
    { c: 1, t: 19 },
    { c: 2, t: 37 },
    { c: 3, t: 25 },
    { c: 4, t: 31 },
    { c: 5, t: 31 },
    { c: 6, t: 30 },
    { c: 7, t: 34 },
    { c: 8, t: 22 },
    { c: 9, t: 26 },
    { c: 10, t: 25 },
    { c: 11, t: 23 },
    { c: 12, t: 17 },
    { c: 13, t: 27 },
    { c: 14, t: 22 },
    { c: 15, t: 21 },
    { c: 16, t: 21 },
    { c: 17, t: 27 },
    { c: 18, t: 23 },
    { c: 19, t: 15 },
    { c: 20, t: 18 },
    { c: 21, t: 14 },
    { c: 22, t: 30 },
    { c: 23, t: 40 },
    { c: 24, t: 10 },
    { c: 25, t: 38 },
    { c: 26, t: 24 },
    { c: 27, t: 22 },
    { c: 28, t: 17 },
    { c: 29, t: 32 },
    { c: 30, t: 24 },
    { c: 31, t: 40 },
    { c: 32, t: 44 },
    { c: 33, t: 26 },
    { c: 34, t: 22 },
    { c: 35, t: 19 },
    { c: 36, t: 32 },
    { c: 37, t: 21 },
    { c: 38, t: 28 },
    { c: 39, t: 18 },
    { c: 40, t: 16 },
    { c: 41, t: 18 },
    { c: 42, t: 22 },
    { c: 43, t: 13 },
    { c: 44, t: 30 },
    { c: 45, t: 5 },
    { c: 46, t: 28 },
    { c: 47, t: 7 },
    { c: 48, t: 47 },
    { c: 49, t: 39 },
    { c: 50, t: 46 },
    { c: 51, t: 64 },
    { c: 52, t: 34 },
  ],
  'lamentations': [
    { c: 1, t: 22 },
    { c: 2, t: 22 },
    { c: 3, t: 66 },
    { c: 4, t: 22 },
    { c: 5, t: 22 },
  ],
  'ezekiel': [
    { c: 1, t: 28 },
    { c: 2, t: 10 },
    { c: 3, t: 27 },
    { c: 4, t: 17 },
    { c: 5, t: 17 },
    { c: 6, t: 14 },
    { c: 7, t: 27 },
    { c: 8, t: 18 },
    { c: 9, t: 11 },
    { c: 10, t: 22 },
    { c: 11, t: 25 },
    { c: 12, t: 28 },
    { c: 13, t: 23 },
    { c: 14, t: 23 },
    { c: 15, t: 8 },
    { c: 16, t: 63 },
    { c: 17, t: 24 },
    { c: 18, t: 32 },
    { c: 19, t: 14 },
    { c: 20, t: 49 },
    { c: 21, t: 32 },
    { c: 22, t: 31 },
    { c: 23, t: 49 },
    { c: 24, t: 27 },
    { c: 25, t: 17 },
    { c: 26, t: 21 },
    { c: 27, t: 36 },
    { c: 28, t: 26 },
    { c: 29, t: 21 },
    { c: 30, t: 26 },
    { c: 31, t: 18 },
    { c: 32, t: 32 },
    { c: 33, t: 33 },
    { c: 34, t: 31 },
    { c: 35, t: 15 },
    { c: 36, t: 38 },
    { c: 37, t: 28 },
    { c: 38, t: 23 },
    { c: 39, t: 29 },
    { c: 40, t: 49 },
    { c: 41, t: 26 },
    { c: 42, t: 20 },
    { c: 43, t: 27 },
    { c: 44, t: 31 },
    { c: 45, t: 25 },
    { c: 46, t: 24 },
    { c: 47, t: 23 },
    { c: 48, t: 35 },
  ],
  'daniel': [
    { c: 1, t: 21 },
    { c: 2, t: 49 },
    { c: 3, t: 30 },
    { c: 4, t: 37 },
    { c: 5, t: 31 },
    { c: 6, t: 28 },
    { c: 7, t: 28 },
    { c: 8, t: 27 },
    { c: 9, t: 27 },
    { c: 10, t: 21 },
    { c: 11, t: 45 },
    { c: 12, t: 13 },
  ],
  'hosea': [
    { c: 1, t: 11 },
    { c: 2, t: 23 },
    { c: 3, t: 5 },
    { c: 4, t: 19 },
    { c: 5, t: 15 },
    { c: 6, t: 11 },
    { c: 7, t: 16 },
    { c: 8, t: 14 },
    { c: 9, t: 17 },
    { c: 10, t: 15 },
    { c: 11, t: 12 },
    { c: 12, t: 14 },
    { c: 13, t: 16 },
    { c: 14, t: 9 },
  ],
  'joel': [
    { c: 1, t: 20 },
    { c: 2, t: 32 },
    { c: 3, t: 21 },
  ],
  'amos': [
    { c: 1, t: 15 },
    { c: 2, t: 16 },
    { c: 3, t: 15 },
    { c: 4, t: 13 },
    { c: 5, t: 27 },
    { c: 6, t: 14 },
    { c: 7, t: 17 },
    { c: 8, t: 14 },
    { c: 9, t: 15 },
  ],
  'obadiah': [
    { c: 1, t: 21 },
  ],
  'jonah': [
    { c: 1, t: 17 },
    { c: 2, t: 10 },
    { c: 3, t: 10 },
    { c: 4, t: 11 },
  ],
  'micah': [
    { c: 1, t: 16 },
    { c: 2, t: 13 },
    { c: 3, t: 12 },
    { c: 4, t: 13 },
    { c: 5, t: 15 },
    { c: 6, t: 16 },
    { c: 7, t: 20 },
  ],
  'nahum': [
    { c: 1, t: 15 },
    { c: 2, t: 13 },
    { c: 3, t: 19 },
  ],
  'habakkuk': [
    { c: 1, t: 17 },
    { c: 2, t: 20 },
    { c: 3, t: 19 },
  ],
  'zephaniah': [
    { c: 1, t: 18 },
    { c: 2, t: 15 },
    { c: 3, t: 20 },
  ],
  'haggai': [
    { c: 1, t: 15 },
    { c: 2, t: 23 },
  ],
  'zechariah': [
    { c: 1, t: 21 },
    { c: 2, t: 13 },
    { c: 3, t: 10 },
    { c: 4, t: 14 },
    { c: 5, t: 11 },
    { c: 6, t: 15 },
    { c: 7, t: 14 },
    { c: 8, t: 23 },
    { c: 9, t: 17 },
    { c: 10, t: 12 },
    { c: 11, t: 17 },
    { c: 12, t: 14 },
    { c: 13, t: 9 },
    { c: 14, t: 21 },
  ],
  'malachi': [
    { c: 1, t: 14 },
    { c: 2, t: 17 },
    { c: 3, t: 18 },
    { c: 4, t: 6 },
  ],
  'matthew': [
    { c: 1, t: 25 },
    { c: 2, t: 23 },
    { c: 3, t: 17 },
    { c: 4, t: 25 },
    { c: 5, t: 48 },
    { c: 6, t: 34 },
    { c: 7, t: 29 },
    { c: 8, t: 34 },
    { c: 9, t: 38 },
    { c: 10, t: 42 },
    { c: 11, t: 30 },
    { c: 12, t: 50 },
    { c: 13, t: 58 },
    { c: 14, t: 36 },
    { c: 15, t: 39 },
    { c: 16, t: 28 },
    { c: 17, t: 27 },
    { c: 18, t: 35 },
    { c: 19, t: 30 },
    { c: 20, t: 34 },
    { c: 21, t: 46 },
    { c: 22, t: 46 },
    { c: 23, t: 39 },
    { c: 24, t: 51 },
    { c: 25, t: 46 },
    { c: 26, t: 75 },
    { c: 27, t: 66 },
    { c: 28, t: 20 },
  ],
  'mark': [
    { c: 1, t: 45 },
    { c: 2, t: 28 },
    { c: 3, t: 35 },
    { c: 4, t: 41 },
    { c: 5, t: 43 },
    { c: 6, t: 56 },
    { c: 7, t: 37 },
    { c: 8, t: 38 },
    { c: 9, t: 50 },
    { c: 10, t: 52 },
    { c: 11, t: 33 },
    { c: 12, t: 44 },
    { c: 13, t: 37 },
    { c: 14, t: 72 },
    { c: 15, t: 47 },
    { c: 16, t: 8 },
  ],
  'luke': [
    { c: 1, t: 80 },
    { c: 2, t: 52 },
    { c: 3, t: 38 },
    { c: 4, t: 44 },
    { c: 5, t: 39 },
    { c: 6, t: 49 },
    { c: 7, t: 50 },
    { c: 8, t: 56 },
    { c: 9, t: 62 },
    { c: 10, t: 42 },
    { c: 11, t: 54 },
    { c: 12, t: 59 },
    { c: 13, t: 35 },
    { c: 14, t: 35 },
    { c: 15, t: 32 },
    { c: 16, t: 31 },
    { c: 17, t: 37 },
    { c: 18, t: 43 },
    { c: 19, t: 48 },
    { c: 20, t: 47 },
    { c: 21, t: 38 },
    { c: 22, t: 71 },
    { c: 23, t: 56 },
    { c: 24, t: 53 },
  ],
  'john': [
    { c: 1, t: 51 },
    { c: 2, t: 25 },
    { c: 3, t: 36 },
    { c: 4, t: 54 },
    { c: 5, t: 47 },
    { c: 6, t: 71 },
    { c: 7, t: 52 },
    { c: 8, t: 59, i: 12 },
    { c: 9, t: 41 },
    { c: 10, t: 42 },
    { c: 11, t: 57 },
    { c: 12, t: 50 },
    { c: 13, t: 38 },
    { c: 14, t: 31 },
    { c: 15, t: 27 },
    { c: 16, t: 33 },
    { c: 17, t: 26 },
    { c: 18, t: 40 },
    { c: 19, t: 42 },
    { c: 20, t: 31 },
    { c: 21, t: 25 },
  ],
  'acts': [
    { c: 1, t: 26 },
    { c: 2, t: 47 },
    { c: 3, t: 26 },
    { c: 4, t: 37 },
    { c: 5, t: 42 },
    { c: 6, t: 15 },
    { c: 7, t: 60 },
    { c: 8, t: 40 },
    { c: 9, t: 43 },
    { c: 10, t: 48 },
    { c: 11, t: 30 },
    { c: 12, t: 25 },
    { c: 13, t: 52 },
    { c: 14, t: 28 },
    { c: 15, t: 41 },
    { c: 16, t: 40 },
    { c: 17, t: 34 },
    { c: 18, t: 28 },
    { c: 19, t: 41 },
    { c: 20, t: 38 },
    { c: 21, t: 40 },
    { c: 22, t: 30 },
    { c: 23, t: 35 },
    { c: 24, t: 27 },
    { c: 25, t: 27 },
    { c: 26, t: 32 },
    { c: 27, t: 44 },
    { c: 28, t: 31 },
  ],
  'romans': [
    { c: 1, t: 32 },
    { c: 2, t: 29 },
    { c: 3, t: 31 },
    { c: 4, t: 25 },
    { c: 5, t: 21 },
    { c: 6, t: 23 },
    { c: 7, t: 25 },
    { c: 8, t: 39 },
    { c: 9, t: 33 },
    { c: 10, t: 21 },
    { c: 11, t: 36 },
    { c: 12, t: 21 },
    { c: 13, t: 14 },
    { c: 14, t: 23 },
    { c: 15, t: 33 },
    { c: 16, t: 27 },
  ],
  '1corinthians': [
    { c: 1, t: 31 },
    { c: 2, t: 16 },
    { c: 3, t: 23 },
    { c: 4, t: 21 },
    { c: 5, t: 13 },
    { c: 6, t: 20 },
    { c: 7, t: 40 },
    { c: 8, t: 13 },
    { c: 9, t: 27 },
    { c: 10, t: 33 },
    { c: 11, t: 34 },
    { c: 12, t: 31 },
    { c: 13, t: 13 },
    { c: 14, t: 40 },
    { c: 15, t: 58 },
    { c: 16, t: 24 },
  ],
  '2corinthians': [
    { c: 1, t: 24 },
    { c: 2, t: 17 },
    { c: 3, t: 18 },
    { c: 4, t: 18 },
    { c: 5, t: 21 },
    { c: 6, t: 18 },
    { c: 7, t: 16 },
    { c: 8, t: 24 },
    { c: 9, t: 15 },
    { c: 10, t: 18 },
    { c: 11, t: 33 },
    { c: 12, t: 21 },
    { c: 13, t: 14 },
  ],
  'galatians': [
    { c: 1, t: 24 },
    { c: 2, t: 21 },
    { c: 3, t: 29 },
    { c: 4, t: 31 },
    { c: 5, t: 26 },
    { c: 6, t: 18 },
  ],
  'ephesians': [
    { c: 1, t: 23 },
    { c: 2, t: 22 },
    { c: 3, t: 21 },
    { c: 4, t: 32 },
    { c: 5, t: 33 },
    { c: 6, t: 24 },
  ],
  'philippians': [
    { c: 1, t: 30 },
    { c: 2, t: 30 },
    { c: 3, t: 21 },
    { c: 4, t: 23 },
  ],
  'colossians': [
    { c: 1, t: 29 },
    { c: 2, t: 23 },
    { c: 3, t: 25 },
    { c: 4, t: 18 },
  ],
  '1thessalonians': [
    { c: 1, t: 10 },
    { c: 2, t: 20 },
    { c: 3, t: 13 },
    { c: 4, t: 18 },
    { c: 5, t: 28 },
  ],
  '2thessalonians': [
    { c: 1, t: 12 },
    { c: 2, t: 17 },
    { c: 3, t: 18 },
  ],
  '1timothy': [
    { c: 1, t: 20 },
    { c: 2, t: 15 },
    { c: 3, t: 16 },
    { c: 4, t: 16 },
    { c: 5, t: 25 },
    { c: 6, t: 21 },
  ],
  '2timothy': [
    { c: 1, t: 18 },
    { c: 2, t: 26 },
    { c: 3, t: 17 },
    { c: 4, t: 22 },
  ],
  'titus': [
    { c: 1, t: 16 },
    { c: 2, t: 15 },
    { c: 3, t: 15 },
  ],
  'philemon': [
    { c: 1, t: 25 },
  ],
  'hebrews': [
    { c: 1, t: 14 },
    { c: 2, t: 18 },
    { c: 3, t: 19 },
    { c: 4, t: 16 },
    { c: 5, t: 14 },
    { c: 6, t: 20 },
    { c: 7, t: 28 },
    { c: 8, t: 13 },
    { c: 9, t: 28 },
    { c: 10, t: 39 },
    { c: 11, t: 40 },
    { c: 12, t: 29 },
    { c: 13, t: 25 },
  ],
  'james': [
    { c: 1, t: 27 },
    { c: 2, t: 26 },
    { c: 3, t: 18 },
    { c: 4, t: 17 },
    { c: 5, t: 20 },
  ],
  '1peter': [
    { c: 1, t: 25 },
    { c: 2, t: 25 },
    { c: 3, t: 22 },
    { c: 4, t: 19 },
    { c: 5, t: 14 },
  ],
  '2peter': [
    { c: 1, t: 21 },
    { c: 2, t: 22 },
    { c: 3, t: 18 },
  ],
  '1john': [
    { c: 1, t: 10 },
    { c: 2, t: 29 },
    { c: 3, t: 24 },
    { c: 4, t: 21 },
    { c: 5, t: 21 },
  ],
  '2john': [
    { c: 1, t: 13 },
  ],
  '3john': [
    { c: 1, t: 14 },
  ],
  'jude': [
    { c: 1, t: 25 },
  ],
  'revelation': [
    { c: 1, t: 20 },
    { c: 2, t: 29 },
    { c: 3, t: 22 },
    { c: 4, t: 11 },
    { c: 5, t: 14 },
    { c: 6, t: 17 },
    { c: 7, t: 17 },
    { c: 8, t: 13 },
    { c: 9, t: 21 },
    { c: 10, t: 11 },
    { c: 11, t: 19 },
    { c: 12, t: 17 },
    { c: 13, t: 18 },
    { c: 14, t: 20 },
    { c: 15, t: 8 },
    { c: 16, t: 21 },
    { c: 17, t: 18 },
    { c: 18, t: 24 },
    { c: 19, t: 21 },
    { c: 20, t: 15 },
    { c: 21, t: 27 },
    { c: 22, t: 21 },
  ],
};
