export default {
  development: {
    apiUrl: 'http://127.0.0.1:9090/',
  },

  production: {
    apiUrl: 'https://api.pinzi.org/',
  },

  testing: {},
};
